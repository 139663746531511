import React from 'react';
import Amplify from 'aws-amplify';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import { AuthProvider } from './authContext';
import { SearchProvider } from './searchContext';
import awsconfig from '../awsconfig';
import { FeatureFlagsProvider } from './featureFlagsContext';

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: awsconfig.cognito.REGION,
    userPoolId: awsconfig.cognito.USER_POOL_ID,
    userPoolWebClientId: awsconfig.cognito.APP_CLIENT_ID,
  },
});

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SearchProvider>
          <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
        </SearchProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default AppProviders;
